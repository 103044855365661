import { useEffect } from 'react';
import cx from 'classnames';
import { useClientTranslation } from '../../../../hooks/helm/useClientTranslation';
import { useBottomShadow } from '../../../../hooks/helm/useBottomShadow';

export const ProductHoverContent = ({
    className,
    products,
    title,
    otherTitle,
}: {
    products: string[];
    title?: string;
    otherTitle?: string;
    className?: string;
}) => {
    const { t } = useClientTranslation();
    const { isAtBottom, scrollDivRef, checkScrollPosition } = useBottomShadow();

    useEffect(() => {
        checkScrollPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    const defaultOtherTitle = `${products?.length} ${t('SKU', { count: products?.length })}`;

    return (
        <div
            className={cx(className, {
                'gradient-fade': !isAtBottom,
            })}
        >
            <div className="text-navy-70 flex items-center space-x-2 border-b p-4 align-bottom">
                <div className="text-sm">{title ?? t('SKU List')}</div>
                <div className="mt-[2px] text-xs">{otherTitle ?? defaultOtherTitle}</div>
            </div>
            <div
                ref={scrollDivRef}
                onScroll={checkScrollPosition}
                className="text-navy-70 no-scrollbar max-h-48 space-y-4 overflow-y-scroll p-4 text-xs"
            >
                {products?.map((product, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={product + index}>{product?.replace(' product', '')}</div>
                ))}
            </div>
        </div>
    );
};
