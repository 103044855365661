import Color from 'color';
import _ from 'lodash';
import { SegmentContainerStateType } from '../types/components/consumers';
import {
    BrandSegmentGeoIDTableData,
    BrandSegmentInfo,
    HexaSegmentInfo,
} from '../types/hooks/segment';
import { formatNumber } from './numberFormatHelpers';

const getHexaShade = ({
    shadeOpacity,
    segmentContainerState,
}: {
    shadeOpacity: number;
    segmentContainerState: SegmentContainerStateType;
}) => {
    if (segmentContainerState === 'behavior') return `rgba(240, 112, 57, ${shadeOpacity})`;
    if (segmentContainerState === 'spend') return `rgba(241, 27, 151, ${shadeOpacity})`;
    if (segmentContainerState === 'share') {
        return `rgba(128, 66, 227, ${shadeOpacity})`;
    }
    // ? Hexa's default color ------ No Brand
    return `rgba(34, 38, 45, ${shadeOpacity})`;
};

const getSegmentContainerState = (str: SegmentContainerStateType) => {
    if (str === 'behavior') return ['More Price Inelastic', 'More Price Elastic'];
    if (str === 'spend') return ['Lower Contribution', 'Higher Contribution'];
    // ? 'share'
    return ['lower market share', 'higher market share'];
};

const getSegmentHexaMapDisplayValue = ({
    brand,
    segmentContainerState,
    segmentData,
}: {
    segmentContainerState: SegmentContainerStateType;
    segmentData?: HexaSegmentInfo;
    brand?: string;
}) => {
    if (segmentContainerState === 'behavior') return segmentData?.brand_elasticity_pct_rank;
    if (segmentContainerState === 'spend')
        return brand && brand.length > 0
            ? segmentData?.brand_segment_wallet_share
            : segmentData?.segment_sales_pct;

    if (segmentContainerState === 'share') return segmentData?.brand_cat_segment_wallet_share;

    return segmentData?.segment_sales_pct;
};

const getSegmentGeoMapDisplayValue = ({
    brandGeoData,
    segmentContainerState,
    segmentData,
}: {
    segmentContainerState: SegmentContainerStateType;
    segmentData?: BrandSegmentInfo;
    brandGeoData?: BrandSegmentGeoIDTableData[] | BrandSegmentGeoIDTableData;
}) => {
    if (!segmentData) return undefined;
    // ? Price Elasticity is the same as in SegmentHexaMap
    if (segmentContainerState === 'behavior') return segmentData;
    const geoData = Array.isArray(brandGeoData)
        ? brandGeoData?.filter(geo => geo.segment_id === segmentData.segment_id)[0]
        : brandGeoData;
    if (!geoData) return undefined;
    // ? Spend
    let displayValue = geoData.segment_brand_geo_sales_percent_of_brand_geo_sales;
    // ? Market Share
    if (segmentContainerState === 'share') {
        displayValue = geoData.segment_brand_geo_sales_percent_of_category_geo_sales;
    }
    return {
        ...segmentData,
        displayValue,
    };
};

const getRGBasArray = (color: string) => Color(color).rgb().array() as [number, number, number];

const getNumbersOrdinal = (num?: number) => formatNumber(num * 100, '0o');

const getHeader = ({
    brand,
    segmentContainerState,
    showArea,
    category,
}: {
    segmentContainerState?: SegmentContainerStateType;
    brand?: string;
    showArea: boolean;
    category?: string;
}) => {
    let header = '';
    if (segmentContainerState === 'behavior')
        header = `Rank OF ${brand ? ` ${brand} ` : ''}Price Elasticity`;
    else if (segmentContainerState === 'absolute') header = '% OF Grocery SPEND';
    else if (segmentContainerState === 'share')
        header = `% OF ${category ? ` ${category} ` : 'CATEGORY '}MARKET SHARE`;
    else if (segmentContainerState === 'spend') {
        if (!brand) {
            header = '% OF Grocery';
        } else header = `% OF CONTRIBUTION TO SALES`;
    }
    if (showArea && segmentContainerState !== 'behavior') header += ' IN THIS AREA';

    return header;
};

export {
    getHexaShade,
    getRGBasArray,
    getSegmentContainerState,
    getSegmentHexaMapDisplayValue,
    getSegmentGeoMapDisplayValue,
    getNumbersOrdinal,
    getHeader,
};
