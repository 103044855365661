import { ScatterplotLayer } from '@deck.gl/layers';
import Color from 'color';
import bbox from '@turf/bbox';
import { PickingInfo } from 'deck.gl';
import { useMemo } from 'react';
import { excludePocs, StrategyOverviewMapped } from './util';
import { getRGBasArray } from '../../../utils/consumersHelpers';
import { ChartMetric } from './types';
import useStore from '../components/state/state';
import { CountryOptions } from './config';
import { PocWithIssue } from '../pages/pocExplorer/types';

interface Location {
    latitude: number;
    longitude: number;
}

// this function returns only the points OUTSIDE the bounding box you give it.
function filterPointsOutsideBoundingBox(pocs: Location[], minLat, maxLat, minLong, maxLong) {
    const filteredPocs = pocs.filter(
        poc =>
            poc.latitude < minLat ||
            poc.latitude > maxLat ||
            poc.longitude < minLong ||
            poc.longitude > maxLong,
    );

    return filteredPocs;
}

// this function returns only the points INSIDE the bounding box you give it.
function filterPointsInsideBoundingBox(pocs: Location[], minLat, maxLat, minLong, maxLong) {
    const filteredPocs = pocs.filter(
        poc =>
            poc.latitude >= minLat &&
            poc.latitude <= maxLat &&
            poc.longitude >= minLong &&
            poc.longitude <= maxLong,
    );

    return filteredPocs;
}

export const pocsOutsideRegionBoundsOnly = (pocs: Location[], geoJsonFeatureCollection) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(geoJsonFeatureCollection);
    return filterPointsOutsideBoundingBox(pocs, minLat, maxLat, minLng, maxLng);
};

export const pocsInsideRegionBoundsOnly = (pocs: Location[], geoJsonFeatureCollection) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(geoJsonFeatureCollection);
    return filterPointsInsideBoundingBox(pocs, minLat, maxLat, minLng, maxLng);
};

export const buildPOCLayer = (
    id: string,
    data: Location[],
    onClick: (pickingInfo: PickingInfo, event: any) => void, // event is a MjolnirEvent but it's not important
    onHover: (pickingInfo: PickingInfo, event: any) => void,
    hoveredStore,
    colorFn: (d: StrategyOverviewMapped) => [number, number, number],
    visible = true,
    chartMetric?: string,
    lineColorFn?: (d: StrategyOverviewMapped) => [number, number, number],
) => {
    // we are using the FeatureCollection to filter out which pocs we show on the map
    const layer = new ScatterplotLayer<StrategyOverviewMapped>({
        id,
        data,
        visible,
        getPosition: d => [d.longitude, d.latitude],
        getFillColor(d: StrategyOverviewMapped) {
            const color = colorFn(d);
            const hovered = hoveredStore && hoveredStore?.vendor_accountid === d?.vendor_accountid;
            return (hovered ? Color(color).lighten(0.2).rgb().array() : color) as [
                number,
                number,
                number,
            ];
        },
        getLineColor: d => {
            if (lineColorFn) return lineColorFn(d);
            return getRGBasArray('black');
        },
        getLineWidth: d => {
            if (chartMetric && chartMetric !== ChartMetric.Coverage) {
                const value = d[chartMetric.toLowerCase()];
                if (!value) {
                    return 0.25;
                }
            }
            return 1;
        },
        getRadius: () => 3.5,
        radiusMaxPixels: 5,
        radiusMinPixels: 3,
        radiusScale: 0.001,
        radiusUnits: 'common',
        lineWidthUnits: 'common',
        lineWidthMaxPixels: 1,
        lineWidthScale: 1.5,
        stroked: true,
        pickable: true,
        filled: true,
        onClick,
        onHover,
        updateTriggers: {
            getFillColor: [hoveredStore],
        },
    });
    return layer;
};

export const buildPOCLayerPreviewStrategy = (
    id: string,
    data: any[],
    onClick,
    onHover,
    hoveredStore,
    colorFn: (d: StrategyOverviewMapped) => [number, number, number],
    visible = true,
    radiusFn?: (d: StrategyOverviewMapped) => number,
    pickable?: boolean,
) => {
    const layer = new ScatterplotLayer<StrategyOverviewMapped>({
        id,
        data,
        visible,
        getPosition: d => [d.longitude, d.latitude],
        getFillColor(d: any) {
            return colorFn(d);
        },
        getRadius: radiusFn,
        stroked: true,
        pickable: true,
        filled: true,
        onClick,
        onHover,
        radiusScale: 1,
        radiusUnits: 'pixels',
        lineWidthUnits: 'pixels',
        lineWidthMinPixels: 0.05,
        lineWidthMaxPixels: 0.05,
        lineWidthScale: 1.5,
    });
    return layer;
};

export const buildLayerPOCExplorer = (
    id: string,
    data: any[],
    getFillColor: (d: PocWithIssue) => [number, number, number],
    hoveredIssue: string,
    getLineColor: (d: PocWithIssue) => [number, number, number],
    onHover,
    onClick,
    getRadius,
    visible = true,
) => {
    const layer = new ScatterplotLayer<PocWithIssue>({
        id,
        data,
        visible,
        getPosition: d => [d.longitude, d.latitude],
        getFillColor,
        radiusScale: 1,
        radiusUnits: 'pixels',
        getRadius,
        getLineColor,
        lineWidthUnits: 'common',
        lineWidthMaxPixels: 1,
        lineWidthScale: 1.5,
        stroked: true,
        pickable: true,
        filled: true,
        updateTriggers: {
            getRadius: [hoveredIssue, getRadius],
        },
        onHover,
        onClick,
    });
    return layer;
};

const COUNTRY_BOUNDS = {
    [CountryOptions.Ecuador]: { NORTH: 2, SOUTH: -6, EAST: -75, WEST: -92 },
};

const useLayerPoints = customersInfoData => {
    const country = useStore(state => state.country);
    const demoMode = useStore(state => state.demoMode);

    const layerPoints = useMemo(
        () =>
            customersInfoData.filter(
                d =>
                    isFinite(d.longitude) &&
                    isFinite(d.latitude) &&
                    !excludePocs.includes(d.vendor_accountid) &&
                    !demoMode &&
                    d.longitude >= COUNTRY_BOUNDS[country].WEST &&
                    d.longitude <= COUNTRY_BOUNDS[country].EAST &&
                    d.latitude >= COUNTRY_BOUNDS[country].SOUTH &&
                    d.latitude <= COUNTRY_BOUNDS[country].NORTH,
            ),
        [customersInfoData, demoMode, country],
    );

    return layerPoints;
};

export default useLayerPoints;
