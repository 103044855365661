import { useRef, useState } from 'react';

export const useRightShadow = () => {
    const [isAtRight, setIsAtRight] = useState(false);
    const scrollDivRef = useRef(null);

    const checkScrollPosition = () => {
        if (!scrollDivRef.current) {
            return;
        }
        const { scrollLeft, scrollWidth, clientWidth } = scrollDivRef.current;

        // Allow a small margin of error to account for rounding discrepancies
        const marginOfError = 1; // Adjust this value if needed
        const atRight = scrollLeft + clientWidth + marginOfError >= scrollWidth;

        setIsAtRight(atRight);
    };

    return {
        checkScrollPosition,
        isAtRight,
        scrollDivRef,
    };
};
