import { useRef, useState } from 'react';

export const useBottomShadow = () => {
    const [isAtBottom, setIsAtBottom] = useState(false);
    const scrollDivRef = useRef(null);

    const checkScrollPosition = () => {
        if (!scrollDivRef.current) {
            return;
        }
        const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current;
        const atBottom = scrollTop + clientHeight >= scrollHeight;

        setIsAtBottom(atBottom);
    };

    return {
        checkScrollPosition,
        isAtBottom,
        scrollDivRef,
    };
};
