import { HoverCard, HoverCardProps } from '@mantine/core';
import { FC } from 'react';
import cx from 'classnames';
import { capitalizeFirst, mapProductToMock, strategyProductDisplay } from '../util/util';
import { ProductHoverContent } from './productHoverCards/productHoverContent';
import { useClientTranslation } from '../../../hooks/helm/useClientTranslation';
import { useStrategyDataWithFocusSkuGroups } from '../api/apiHooks';
import { ProductHoverCardFocusSkuGroups } from './productHoverCards/productHoverCardFocusSkuGroups';
import { Spinner } from '../../../andromeda/Spinners/spinner';
import { ChartMetric } from '../util/types';
import useStore from './state/state';

interface ProductsCellProps {
    strategyId: number;
    strategyDataProducts: string[];
    hoverCardProps?: HoverCardProps;
    targetClassName?: string;
    capitalize?: boolean;
}

export const ProductsCell: FC<ProductsCellProps> = ({
    strategyId,
    strategyDataProducts,
    hoverCardProps,
    targetClassName,
    capitalize = true,
}) => {
    const demoMode = useStore(state => state.demoMode);
    const forceShowRealSkuNames = useStore(state => state.forceShowRealSkuNames);
    const { t } = useClientTranslation();
    const { totalFocusProducts, focusSkusFullName, mappedGroups, strategySkuGroupsLoading } =
        useStrategyDataWithFocusSkuGroups({ strategyId: strategyId as unknown as string });

    if (strategySkuGroupsLoading) return <Spinner size={25} />;
    if (!strategyDataProducts || strategyDataProducts.length === 0) {
        return (
            <HoverCard shadow="md" position="right" withinPortal {...hoverCardProps}>
                <HoverCard.Target>
                    <span className={cx('text-sm font-medium text-navy', targetClassName)}>
                        {`${totalFocusProducts} ${t('SKU', { count: totalFocusProducts })}`}
                    </span>
                </HoverCard.Target>
                <HoverCard.Dropdown className="!p-0">
                    <ProductHoverCardFocusSkuGroups
                        totalFocusProducts={totalFocusProducts}
                        focusSkusFullName={focusSkusFullName.map(({ full_name }) => full_name)}
                        mappedGroups={mappedGroups}
                        loading={strategySkuGroupsLoading}
                    />
                </HoverCard.Dropdown>
            </HoverCard>
        );
    }

    if (strategyDataProducts.length > 1) {
        const productsDisplay =
            strategyDataProducts.map(item =>
                demoMode && !forceShowRealSkuNames ? mapProductToMock(item) : item,
            ) || [];
        return (
            <HoverCard shadow="md" position="right" withinPortal {...hoverCardProps}>
                <HoverCard.Target>
                    <span className={cx('text-sm font-medium text-navy', targetClassName)}>
                        {capitalize
                            ? capitalizeFirst(
                                  strategyProductDisplay(productsDisplay, ChartMetric.Revenue, t),
                              )
                            : strategyProductDisplay(productsDisplay, ChartMetric.Revenue, t)}
                    </span>
                </HoverCard.Target>
                <HoverCard.Dropdown className="!p-0">
                    <ProductHoverContent products={productsDisplay} />
                </HoverCard.Dropdown>
            </HoverCard>
        );
    }

    return (
        <span className={cx('text-sm font-medium text-navy', targetClassName)}>
            {capitalize
                ? capitalizeFirst(
                      strategyProductDisplay(strategyDataProducts, ChartMetric.Revenue, t),
                  )
                : strategyProductDisplay(strategyDataProducts, ChartMetric.Revenue, t)}
        </span>
    );
};
