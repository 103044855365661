import { useEffect } from 'react';
import { useClientTranslation } from '../../../../hooks/helm/useClientTranslation';
import { SkuGroup } from '../../util/types';
import { useBottomShadow } from '../../../../hooks/helm/useBottomShadow';

const GroupSection = ({ group }: { group: SkuGroup }) => {
    const { t } = useClientTranslation();
    return (
        <div>
            <div className="text-navy-50 mb-4 text-xs">
                {t('group')}: {group.group_sku_name}
            </div>
            <div className="space-y-4">
                {group.skus_full_name?.map((sku: string) => (
                    <div className="mt-2 text-xs" key={sku}>
                        {sku}
                    </div>
                ))}
            </div>
        </div>
    );
};

interface ProductHoverCardFocusSkuGroupsProps {
    totalFocusProducts: number;
    focusSkusFullName: string[];
    mappedGroups: SkuGroup[];
    loading: boolean;
}

export const ProductHoverCardFocusSkuGroups = ({
    totalFocusProducts,
    focusSkusFullName,
    mappedGroups,
    loading,
}: ProductHoverCardFocusSkuGroupsProps) => {
    const { t } = useClientTranslation();
    const { isAtBottom, scrollDivRef, checkScrollPosition } = useBottomShadow();

    useEffect(() => {
        checkScrollPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusSkusFullName]); // what does this do?

    if (loading) return null;
    return (
        <div className={!isAtBottom ? 'gradient-fade' : ''}>
            <div className="text-navy-70 flex items-center space-x-2 border-b p-4 align-bottom">
                <div className="text-sm">{t('SKU List')}</div>
                <div className="mt-[2px] text-xs">
                    {totalFocusProducts} {t('SKU', { count: totalFocusProducts })}
                </div>
            </div>
            <div
                ref={scrollDivRef}
                onScroll={checkScrollPosition}
                className="text-navy-70 no-scrollbar h-48 space-y-4 overflow-y-scroll p-4 text-xs"
            >
                {focusSkusFullName.map(product => (
                    <div key={product}>{product?.replace(' product', '')}</div>
                ))}
                {mappedGroups.map(group => (
                    <GroupSection key={group.group_sku_name} group={group} />
                ))}
            </div>
        </div>
    );
};
